<template>
    <div style="padding-bottom:80px">

        <div class="orderForm">
            <van-cell-group>
                <van-cell title="客户剩余短信条数" :value="form.smsBalance"/>
                <van-cell title="客户发送短信条数" :value="form.totalSendCount"/>
                <van-cell title="短信单价" :value="form.smsPrice"/>
                <van-field name="stepper" label="条数">
                    <template #input>
                        <van-stepper v-model="form.smsCount" :min="1" :max="99999" integer input-width="100px" button-size="28" />
                    </template>
                </van-field>
            </van-cell-group>
            <van-submit-bar v-if="flag"
                :price="price * 100"
                button-text="提交订单"
                @submit="onSubmit">
            </van-submit-bar>

        </div>
    </div>
</template>
<script>
import { Toast } from "vant";
import wx from "weixin-js-sdk";
export default {
    name:'smsCharge',
    data() {
        return {
            flag:true,
            arrs:{},
            form:{
                smsBalance:'',
                totalSendCount:'',
                smsCount:0,
                smsPrice:1
            },
            pack:[],
            cardStatus:{
                type:'success',
                label:'正常'
            },
            current:0,
            chargeOrderId:'',
            timer:Object
        }
    },
    computed:{
        price(){
            return this.form.smsCount * this.form.smsPrice || 0
        },
    },
    methods: {
        payFn(d){
                if (!this.$store.state.wxSDKReady) {
                    setTimeout(this.payFn, 1000);
                    return;
                }
                this.checkHasPay();
                wx.chooseWXPay({
                    timestamp: d.data.timestamp,
                    nonceStr:  d.data.nonceStr,
                    package:   d.data.packAge,
                    signType: "MD5",
                    paySign:   d.data.sign,
                    success: res => {

                    }
                });
        },
        createAccChargeOrder(){
            let params = {amount:this.price,
            // orderForm:1,
            payWay:'weixin-official'};
            this.$api.createAccChargeOrder(params).then(res => {
                if(res.data.code ==0){
                    this.chargeOrderId = res.data.data.orderId
                    this.createAccChargeOrderPay();
                }
            })
        },
        /**
         * 创建账户充值订单
         * @param chargeOrderId
         */
        createAccChargeOrderPay(){
            Toast.loading({duration: 0,mask: true, forbidClick: true });
            let params = {orderId:this.chargeOrderId, payWay:'weixin-official',openId:localStorage.openId};
            this.$api.createOrderPay(params).then(res => {
                Toast.clear();
                let d = res.data;
                if (d.code !== 0) {
                    Toast(d.msg);
                    return;
                }
                this.payFn(d);
            });
        },

        /**
         * 创建账户充值订单
         * @param chargeOrderId
         */
        createAccChargeOrderPay2(){
            Toast.loading({duration: 0,mask: true, forbidClick: true });
            let params = {orderId:this.chargeOrderId, payWay:'weixin-official',openId:localStorage.openId};
            this.$api.createOrderPay2(params).then(res => {
                Toast.clear();
                let d = res.data;
                if (d.code !== 0) {
                    Toast(d.msg);
                    return;
                }
                this.payFn(d);
            });
        },
        checkHasPay(){

            let check = async () =>{
                let params = {chargeOrderId:this.chargeOrderId};
                Toast.loading({duration: 0,mask: true, forbidClick: true });
                let res = await this.$api.queryHasPayed(params);
                //已经支付
                if(!!res.data.data){
                    Toast.clear();
                    // clearInterval(a);
                    if(localStorage.groupClientFlag == '1'){
                        Toast.success('充值成功');
                        this.$router.push({name:'smsPay'}); 
                    }else{
                        this.createRefueingOrder();
                    }
                } else {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    await check();
                }
            }
            check();
        },

        
        createRefueingOrder(){
            let params ={
                smsCount:this.form.smsCount,
                orderFrom:1,
                chargeOrderId:this.chargeOrderId
            }
            this.$api.addSmsOrder(params).then(res => {
                if(res.data.code == 0){

                    if(localStorage.groupClientFlag == '1'){
                        this.chargeOrderId = res.data.data
                        this.createAccChargeOrderPay2()
                    }else{
                        localStorage.smsOrderId = res.data.data;
                        // localStorage.removeItem('refueing');
                        Toast.success('充值成功');
                        this.$router.push({name:'smsPay'});
                    }
                    
                }
            })
        },
        onSubmit(){
            localStorage.sms = JSON.stringify({...this.form, price:this.price})
            this.$dialog.confirm({
                title: '短信充值',
                message: `是否确定充值短信？充值后短信数量为${this.form.smsBalance + this.form.smsCount}`
            }).then(() => {
                if(localStorage.groupClientFlag == '1'){
                    this.createRefueingOrder()
                }else{
                    this.createAccChargeOrder();
                }
                // this.$router.push({name:'payRefueing'});
                // this.createAccChargeOrder();
            }).catch(() => {
                // on cancel
            });
        },
    },
    created() {
        this.$store.commit('setPageTitle', "短信充值");
        this.$api.getClientSmsInfo({cardNumber:localStorage.msisdn}).then(res => {
            if(res.data.code == 0)this.form = Object.assign({},{...res.data.data, smsCount:1})
        })
        // if(!localStorage.message){
        //     this.$api.getCardInfo({cardNumber:localStorage.msisdn}).then(res => {
        //         if(res.data.code == 0){
        //             this.arrs = Object.assign({},res.data.data)
        //             localStorage.message = JSON.stringify(this.arrs)
        //              this.cardStatus = this.$returnInfo(this.$store.state.dictionaries.cardStatus, this.arrs.status)
        //         }
        //     })
        // }else{
        //     this.arrs = JSON.parse(localStorage.message)
        //      this.cardStatus = this.$returnInfo(this.$store.state.dictionaries.cardStatus, this.arrs.status)
        // }
    }
}
</script>
<style lang="scss" scoped>
.info{
    width: 750px;
    height: 255px;
    background-color: #209df2;
    &-img{
        float: left;
        width: 195px;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 134px;
    }
    ul{
        float: left;
        margin: 38px 0 0 18px;
        width: 520px;
        li{
            color: #fff;
            font-size: 26px;
            text-align: left;
            height: 61.5px;
            line-height: 61.5px;
        }
    }
}
.orderForm{
    text-align: left;
    .van-submit-bar{
        border-top:1px solid #ddd;
    }
}

.mySwipe{
    width: 90%;margin: 0 auto;font-size: 24px;font-weight: 600;
    .swipeTitle{
        color: #EB5E00;margin: 20px 0px 0 24px ;
        font-size: 22px;
    }
    .swipeDiv{
        text-align: center;padding: 20px 0;
        border:1px solid #ddd;
        margin: 5%;
        
    }
    .active{
        color: #EB5E00;
        border:1px solid sandybrown;
    }
}

</style>